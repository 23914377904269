import {AfterViewInit, Component, inject, OnInit} from '@angular/core';
import {DeleteStatus, GuestType, ItemMode, MasterDataStatus, patchFormValue, PaymentMethod, REGEX} from "../../shared/shared.service";
import {NZ_MODAL_DATA, NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import moment from 'moment';
import {ProxyDynamicService} from '../../shared/service/proxy-dynamic.service';
import {distinctUntilChanged} from 'rxjs';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {TranslateService} from '@ngx-translate/core';
import {Payment} from '../../shared/models/payment.model';
import {TopUpDetail} from '../../shared/models/top-up-detail.model';
import {BillingItemComponent} from '../../billing/billing-item/billing-item.component';
import {DecimalPipe} from '@angular/common';
import {Owner} from '../../shared/models/owner.model';
import {Apartment} from '../../shared/models/apartment.model';

@Component({
  selector: 'app-topup-item',
  templateUrl: './topup-item.component.html',
  styleUrl: './topup-item.component.scss',
  providers: [DecimalPipe]
})
export class TopupItemComponent implements OnInit, AfterViewInit {
  readonly nzModalData = inject(NZ_MODAL_DATA);
  formGroup!: FormGroup;
  itemMode = ItemMode;
  mode = ItemMode.Add;
  data!: any;
  listOwner: any[] = [];
  listPackage: any[] = [];
  listApartment: any[] = [];
  listGuestType: any[] = [];
  listPaymentMethod: any[] = [
    {text: 'cash', value: PaymentMethod.Cash},
    {text: 'transfer', value: PaymentMethod.Transfer},
  ];
  REGEX = REGEX;
  expand = true;
  expandPayment = true;
  listPayment: any[] = [];
  listBillingDetail: any[] = [];
  totalService: number = 0;
  totalDiscount: number = 0;
  totalAfterDiscount: number = 0;
  totalVAT: number = 0;
  vat: number = 10;
  totalPayment: number = 0;
  totalDue: number = 0;
  deleteStatus = DeleteStatus;
  currentDateMax = moment().add(1, 'd').set({h: 0, m: 0, s: 0, ms: -1}).toDate();
  listDiscountPercent = [
    {text: '30%', value: 30},
    {text: '40%', value: 40},
    {text: '50%', value: 50},
    {text: '100%', value: 100},
  ]
  keywordOwner = '';
  keywordApartment = '';
  cancelSearchOwner = false;
  cancelSearchApartment = false;

  get f() {
    return this.formGroup.controls;
  }

  constructor(
    private service: ProxyDynamicService,
    private modalRef: NzModalRef,
    private modalService: NzModalService,
    private fb: FormBuilder,
    private noti: NzNotificationService,
    private translate: TranslateService,
    private decimalPipe: DecimalPipe
  ) {
    this.listGuestType = this.nzModalData.listGuestType;
    this.listPackage = JSON.parse(JSON.stringify(this.nzModalData.listPackage));
    this.mode = this.nzModalData.mode;
    if (this.nzModalData.data) {
      this.data = JSON.parse(JSON.stringify(this.nzModalData.data));
    }
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      id: [undefined],
      ticket_no: [null],
      deposit_date: [moment().toDate(), Validators.required],
      package_id: [null, Validators.required],
      guest_type: [null, Validators.required],
      owner: [[], Validators.required],
      apartment: [[], Validators.required],
      note: [null],
      topup_detail: [null],
      payment_detail: [[]],
      payment_method: [null, Validators.required],
      paid_amount: [null, Validators.required],
      payer: [null, Validators.required]
    })
    this.f['package_id'].valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      this.updateDataToListPayment();
    })
    this.f['guest_type'].valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      this.updateDataToListPayment();
    })
    this.f['paid_amount'].valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value) {
        this.f['payment_method'].setValidators([Validators.required]);
      } else {
        this.f['payment_method'].clearValidators();
      }
      this.f['payment_method'].updateValueAndValidity()
    })
    this.f['payment_method'].valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value === null) {
        this.f['paid_amount'].clearValidators();
      } else {
        this.f['paid_amount'].setValidators([Validators.required]);
      }
      this.f['paid_amount'].updateValueAndValidity()
    })
    this.f['owner'].valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value && value.length > 0) {
        this.keywordOwner = '';
        this.getMoreDataApartment(false);
        if (this.f['apartment'].value.length > 0) {
          this.service.search('apartment',{fields: ['*'], filter: {'id': {'_in': this.f['apartment'].value}}}).then((res) => {
            console.log(res)
            console.log(res.filter((r: Apartment) => value.findIndex((v: string) => v === r.owner) !== -1))
            console.log(res.filter((r: Apartment) => value.findIndex((v: string) => v === r.owner) === -1))
            this.f['apartment'].setValue(res.filter((r: Apartment) => value.findIndex((v: string) => v === r.owner) !== -1).map((item: Apartment) => item.id), {emitEvent: false});
          })
        }
      } else {
        this.listApartment = [];
        this.f['apartment'].setValue([], {emitEvent: false});
      }
    })
    this.f['apartment'].valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
      if (value && value.length > 0) {
        this.listOwner = [];
        value.forEach((id: any) => {
          let obj = null;
          if (typeof id === 'number') {
            obj = this.listApartment.find(r => r.id === id);
          }
          if (typeof id === 'object') {
            obj = id;
          }
          if (obj) {
            this.service.findById('owner', obj.owner, {fields: ['*']}).then((res: Owner) => {
              if (res) {
                res['text'] = res.code + ' - ' + res.name;
                res.value = res.id;
                if (this.listOwner.findIndex(r => r.id === res.id) === -1) {
                  this.listOwner.push(res);
                }
                this.f['owner'].setValue(this.listOwner.map(r => r.id), {emitEvent: false});
              }
            })
            const objPackage = this.listPackage.find(r => r.id === this.f['package_id'].value);
            const objPackageCost = objPackage ? objPackage['package_price'].find((r: any) => r.apartment_type === obj.apartment_type) : null
            if (!this.listPayment.length || (this.listPayment.length && this.listPayment.findIndex(r => r.apartment['id'] === id) === -1)) {
              this.listPayment.push({
                apartment: obj,
                name: obj.code,
                price: objPackageCost ? (this.f['guest_type'].value === GuestType.Guest ? objPackageCost.price_by_guest : objPackageCost.price_by_owner) : null,
                night_count: 1,
                total: null,
                discount: null,
                is_percent: true,
                discount_cost: null,
                total_after_discount: null
              })
            }
          }
        })
        this.listPayment = this.listPayment.filter(r => value.indexOf(r.apartment['id']) !== -1);
        this.updateDataToListPayment()
      } else {
        this.f['owner'].setValue([]);
        this.listPayment = [];
        this.updateDataToListPayment()
      }
    })
  }

  ngAfterViewInit() {
    if (this.mode !== ItemMode.Add) {
      patchFormValue(this.formGroup, this.data, false);
      if (this.mode === ItemMode.View) {
        this.formGroup.disable({emitEvent: false});
      }
      if (this.mode === ItemMode.Edit) {
        if (this.nzModalData.data.owner_name) {
          this.keywordOwner = this.nzModalData.data.owner_name;
          this.getMoreDataOwner(false);
        }
        this.f['owner'].disable({emitEvent: false});
      }
      this.f['owner'].setValue(this.data.owner_id);
      this.f['deposit_date'].setValue(moment(this.data.deposit_date).utc(false).toDate());
      this.service.search('topup_detail', {
        fields: ['*.*'],
        filter: {
          topup_transaction: {'_eq': this.data.id},
          deleted: {'_eq': DeleteStatus.NO}
        }
      }).then(res => {
        this.listPayment = res.map((item: any) => {
          item['name'] = item.apartment.code;
          item.total = (item.night_count || 0) * (item.price || 0);
          this.getTotalAfterDiscount(item);
          return item;
        });
        this.getListBilling();
      })
      this.f['ticket_no'].disable()
      this.f['paid_amount'].setValue(null);
      this.f['payment_method'].setValue(null);
      this.f['payment_method'].clearValidators();
      this.f['paid_amount'].clearValidators();
      this.f['payment_method'].updateValueAndValidity()
      this.f['paid_amount'].updateValueAndValidity()
    }
    this.f['paid_amount'].disable()
  }

  getListBilling() {
    this.service.search('payment', {
      fields: ['*.*.*'],
      filter: {
        'payment_detail': {'topup_transaction': this.data.id}, 'deleted': {'_eq': DeleteStatus.NO}
      }
    }).then((res: Payment[]) => {
      this.listBillingDetail = res.map((item: any) => {
        item['total_received_amount'] = item.payment_detail.length ? item.payment_detail.reduce((a: number, b: any) => {
          return a + b.received_amount;
        }, 0) : 0;
        return item
      });
      this.getTotalBill();
    }).catch((error: any) => {
      this.service.handleError(error)
    });
  }

  updateDataToListPayment() {
    this.listPayment.forEach((item: any) => {
      const obj = this.listApartment.find(r => r.id === item.apartment.id);
      const objPackage = this.listPackage.find(r => r.id === this.f['package_id'].value);
      const objPackageCost = objPackage ? objPackage['package_price'].find((r: any) => r.apartment_type === obj.apartment_type) : null;
      item.price = objPackageCost ? (this.f['guest_type'].value === GuestType.Guest ? objPackageCost.price_by_guest : objPackageCost.price_by_owner) : null;
      item.total = (item.night_count || 0) * (item.price || 0);
      this.getTotalAfterDiscount(item);
    })
  }

  editMode() {
    this.mode = ItemMode.Edit;
    this.formGroup.enable({emitEvent: false});
    this.f['owner'].disable({emitEvent: false});
    this.f['ticket_no'].disable({emitEvent: false});
    if (this.nzModalData.data.owner_name) {
      this.keywordOwner = this.nzModalData.data.owner_name;
      this.getMoreDataOwner(false);
    }
    this.f['owner'].setValue(this.data.owner_id);
    this.listApartment = [];
    this.keywordApartment = ''
    this.getMoreDataApartment(false);
    this.getListBilling();
    this.f['payment_method'].clearValidators();
    this.f['paid_amount'].clearValidators();
    this.f['deposit_date'].setValue(moment(this.data.deposit_date).utc(false).toDate(), {emitEvent: false});
  }

  destroyModal() {
    this.modalRef.destroy()
  }

  onSave() {
    if (!this.validatePaymentDetail()) {
      this.noti.create('error', '', this.translate.instant('top-up.error-discount'));
      return;
    }
    if (!this.validateRequiredPaymentDetail()) {
      this.noti.create('error', '', this.translate.instant('top-up.night-required'));
      return;
    }
    if (!this.validateRequiredPrice()) {
      this.noti.create('error', '', this.translate.instant('top-up.price-required'));
      return;
    }
    if (this.f['paid_amount'].value > this.totalPayment) {
      this.noti.create('error', '', this.translate.instant('top-up.error-total'));
      return;
    }
    if (this.mode === ItemMode.Edit && this.listBillingDetail.length && this.totalDue < 0) {
      this.noti.create('error', '', this.translate.instant('top-up.error-due', {value: this.decimalPipe.transform(
          this.listBillingDetail.reduce((a: number, b: any) => {
            return a + b.total_received_amount;
          }, 0))
      }));
      return;
    }
    if (this.formGroup.valid) {
      this.f['topup_detail'].setValue(this.listPayment);
      const body = this.formGroup.getRawValue();
      body.deposit_date = moment(body.deposit_date).format('YYYY-MM-DD');
      body.ticket_no = undefined;
      body.payment_method = undefined;
      body.paid_amount = undefined;
      body.topup_detail = body.topup_detail.map((item: any) => {
        let obj = {
          id: item.id || undefined,
          apartment: item.apartment.id,
          discount: item.discount,
          night_count: item.night_count,
          is_percent: item.is_percent,
          price: item.price
        }
        return obj;
      })
      if (this.mode === ItemMode.Add) {
        body.id = undefined;
        body.payment_detail = undefined;
        this.service.createItem('topup/create', body).subscribe((resTopUp: any) => {
          const payment: Payment = {
            payment_date: moment().toDate(),
            payment_method: this.f['payment_method'].value,
            owner: body.owner,
            payment_detail: [
              {
                received_amount: this.f['payment_method'].value ? this.f['paid_amount'].value : 0,
                topup_transaction: resTopUp['topup_transaction_id']
              }
            ]
          }
          if (this.f['payment_method'].value) {
            this.service.createItem('payment/create', payment).subscribe(() => {
              this.modalRef.destroy(true);
            }, (error: any) => {
              this.service.handleError(error)
            });
          } else {
            this.modalRef.destroy(true);
          }
        }, (error: any) => {
         this.service.handleError(error);
        });
      } else if (this.mode === ItemMode.Edit) {
        this.service.updateItem('topup', body.id || 0, body).subscribe(() => {
          if (body.payment_detail.length) {
            body.payment_detail.forEach((item: any) => {
              const topup = this.data.payment_detail.find((r: any) => r.id === item);
              const obj = {
                id: topup.id,
                received_amount: topup.received_amount,
                topup_transaction: topup['topup_transaction'].id
              }
              this.service.edit('payment_detail', topup.id, obj).then(() => {
                this.modalRef.destroy(true);
              }).catch((error: any) => {
                this.service.handleError(error)
              });
            })
          } else {
            this.modalRef.destroy(true);
          }
        }, (error: any) => {
         this.service.handleError(error);
        });
      }
    } else {
      Object.values(this.formGroup.controls).forEach(control => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({onlySelf: true});
        }
      });
    }
  }

  validatePaymentDetail() {
    let valid = true;
    for (let i = 0; i < this.listPayment.length; i++) {
      if ((this.listPayment[i].total || 0) < (this.listPayment[i].discount_cost || 0)) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  validateRequiredPaymentDetail() {
    let valid = true;
    for (let i = 0; i < this.listPayment.length; i++) {
      if (!this.listPayment[i].night_count) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  validateRequiredPrice() {
    let valid = true;
    for (let i = 0; i < this.listPayment.length; i++) {
      if (!this.listPayment[i].price) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  onChangeNight(item: TopUpDetail, event: any) {
    item.night_count = event;
    item.total = (item.night_count || 0) * (item.price || 0);
    this.getTotalAfterDiscount(item);
  }

  onChangeDiscount(item: TopUpDetail, event: any) {
    if (item.is_percent) {
      item.discount = event;
    } else {
      item.discount = event ? event : 0;
    }
    this.getTotalAfterDiscount(item);
  }

  onChangePercent(item: TopUpDetail, event: any) {
    item.is_percent = event;
    this.getTotalAfterDiscount(item);
  }

  getTotalAfterDiscount(item: TopUpDetail) {
    if (item.is_percent) {
      item.discount_cost = item.discount ? Math.round((item.total || 0) * item.discount / 100) : 0;
      item.total_after_discount = (item.total || 0) - (item.discount_cost || 0);
    } else {
      item.discount_cost = item.discount || 0;
      item.total_after_discount = (item.total || 0) - (item.discount_cost || 0);
    }
    this.getTotalBill();
  }

  getTotalBill() {
    this.totalService = this.listPayment.reduce((a, b) => {
      return a + (b.total || 0);
    }, 0);
    this.totalDiscount = this.listPayment.reduce((a, b) => {
      return a + (b.discount_cost || 0);
    }, 0);
    this.totalAfterDiscount = this.listPayment.reduce((a, b) => {
      return a + (b.total_after_discount || 0);
    }, 0);
    this.totalVAT = Math.round(this.totalAfterDiscount * 10 / 100);
    this.totalPayment = this.totalAfterDiscount + this.totalVAT;
    if (this.listBillingDetail.length) {
      this.totalDue = this.totalPayment - (this.listBillingDetail ? this.listBillingDetail.reduce((a: number, b: any) => {
        return a + b.total_received_amount;
      }, 0) : 0);
    }
    if (this.mode === this.itemMode.Add) {
      this.f['paid_amount'].setValue(this.totalPayment);
    }
  }

  viewDetailBill(item: Payment) {
    const modalRef = this.modalService.create({
      nzContent: BillingItemComponent,
      nzWidth: '80vw',
      nzClassName: 'modal-top-up',
      nzData: {
        mode: ItemMode.View,
        listApartment: this.listApartment,
        listOwner: this.listOwner,
        listPackage: this.listPackage,
        listGuestType: this.listGuestType,
        listPaymentMethod: this.listPaymentMethod,
        data: item,
        disabledPrint: true
      },
    });
    modalRef.afterClose.subscribe(result => {
      if (result) {
        this.noti.create('success', '', this.translate.instant('billing.updateSuccess'));
        this.getListBilling();
      }
    });
  }

  print() {
    this.modalRef.destroy({print: this.data});
  }

  getMoreDataOwner(event: any) {
    if (!this.cancelSearchOwner) {
      const query = !!this.keywordOwner ? {
        '_or': [{'name': {'_icontains': this.keywordOwner}}, {'code': {'_icontains': this.keywordOwner}}],
        'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}
      } : {'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}}
      this.service.searchWithMeta('owner', {
        filter: query,
        limit: 100,
        page: !event ? 0 : Math.round(this.listOwner.length / 100),
      }).then((res: any) => {
        if (!event && !!this.keywordOwner) {
          this.listOwner = res.data.map((item: any) => {
            item['text'] = item.code + ' - ' + item.name;
            item['value'] = item.id;
            return item;
          });
        } else {
          this.listOwner = this.listOwner.concat(res.data.map((item: any) => {
            item['text'] = item.code + ' - ' + item.name;
            item['value'] = item.id;
            return item;
          }));
        }
        this.cancelSearchOwner = this.listOwner.length === Number(res.total);
      })
    }
  }

  filterDataOwner(event: any) {
    this.keywordOwner = event;
    this.cancelSearchOwner = false;
    if (event) {
      this.getMoreDataOwner(false);
    } else {
      this.listOwner = []
    }
  }

  getMoreDataApartment(event: any) {
    if (!this.cancelSearchApartment) {
      const query = !!this.keywordApartment ? {
        '_or': [{'code': {'_icontains': this.keywordApartment}}, {'owner': {'_in': (this.f['owner'].value.length ? this.f['owner'].value : null)}}],
        'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}
      } : {'owner': {'_in': this.f['owner'].value}, 'status': {'_eq': MasterDataStatus.Active}, 'deleted': {'_eq': DeleteStatus.NO}}
      this.service.searchWithMeta('apartment', {
        filter: query,
        limit: 100,
        page: !event ? 0 : Math.round(this.listApartment.length / 100),
      }).then((res: any) => {
        if (!event && !!this.keywordApartment) {
          this.listApartment = res.data.map((item: any) => {
            item['text'] = item.code;
            item['value'] = item.id;
            return item;
          });
        } else {
          this.listApartment = this.listApartment.concat(res.data.map((item: any) => {
            item['text'] = item.code;
            item['value'] = item.id;
            return item;
          }));
        }
        if (this.mode === ItemMode.Edit) {
          this.f['apartment'].setValue(this.data.apartment_codes.split(',').map((item: string) => {
            let obj = this.listApartment.find(r => r.code === item.trim());
            if (obj) {
              return obj.id;
            }
          }), {emitEvent: false});
        }
        this.cancelSearchApartment = this.listApartment.length === Number(res.total);
      })
    }
  }

  filterDataApartment(event: any) {
    this.keywordApartment = event;
    this.cancelSearchApartment = false;
    if (event) {
      this.getMoreDataApartment(false);
    } else {
      this.listApartment = []
    }
  }
}
